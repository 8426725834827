// Dependency
import React, { useState } from "react"
import { Link } from "gatsby";
import { graphql } from "gatsby";

// Components
import Layout from "../../components/layouts";
import ProductSwiper from "../../components/product-swiper";
import Hero from "../../components/hero/hero";
import BackgroundCards from "../../components/background-cards";
import MediaBanner from "../../components/media-banner";
import ProductContent from "../../components/product/product-content/product-content";

// Hooks

// Internationalization
import { useTranslation } from 'react-i18next';
import ProductList from "../product/hooks/product-list";
import Countdown from "../../components/countdown/countdown";

//GraphQL to get Dato data
export const query = graphql`
query ( $locale: String! = "en"){
   datoData: allDatoCmsProduct(
     sort: { fields: position, order: ASC }
     filter: {locale: {eq: $locale}}
     ) {
      nodes {
        centraId
        id
        title
        slug
        featuredImage {
           fluid(maxWidth: 700) {
              ...GatsbyDatoCmsFluid
            }
        }
        locale
        trustpilotId
        saleBadge
        bestseller
      }
   }
   datoCmsHeader{
    saleLabelColor{
      hex
    }
    saleLabelBackground{
      hex
    }
   }
  datoCmsHomePage (locale: {eq: $locale}){
    locale
    title
    heroContent
    heroLinkText
    heroLink
    mobileHero{
      fluid(maxWidth: 1500) {
        ...GatsbyDatoCmsFluid
      }
    }
    desktopHero{
      fluid(maxWidth: 1500){
        ...GatsbyDatoCmsFluid
      }
    }
    bigScreen{
        fluid(maxWidth: 2500){
        ...GatsbyDatoCmsFluid
      }
    }
     mobileVideo{
      url
      format
    }
    desktopVideo{
      url
      format
    }
    backgroundCards{
      featuredMedia{
          fluid(maxWidth: 500){
            ...GatsbyDatoCmsFluid
        }
        url
      }
      video{
        url
        format
      }
      title
      subtitle
      link
      buttonText
    }
    productHeadline
    products{
      centraId
      slug
      featuredImage{
        fluid(maxWidth: 700)  {
          ...GatsbyDatoCmsFluid
        }
      }
      newPrice
      locale
      title
      parentProductName
      trustpilotId
      saleBadge
      bestseller
    }
    rowGrid{
      image{
          fluid(maxWidth: 1500) {
          ...GatsbyDatoCmsFluid
        }
      }
      video{
        url
      }
      title
      content
      link
      linkText
      textleft
      beige
    }
    mediaBanner{
      mobileImage{
         fluid(maxWidth: 700) {
          ...GatsbyDatoCmsFluid
        }
        url
      }
      desktopImage{
         fluid(maxWidth: 700) {
          ...GatsbyDatoCmsFluid
        }
        url
      }
      video{
        url
        format
      }
      mobileVideo{
        url
        format
      }
      audiobleVideo{
        url
      }
      audibleVideoMobile{
        url
      }
      bannerText
    }
    seoMetaTags {
      tags
      id
    }
  }
  datoCmsModal (locale: {eq: $locale}){
    title
    content
  }
}
`

// Template
const HomePage = (props) => {
  const pageData = props.data.datoCmsHomePage;

  // Hero media
  const mediaData = {
    videoSrcURL: pageData && pageData.desktopVideo ? pageData.desktopVideo.url : '',
    videoSrcURLMobile: pageData && pageData.mobileVideo ? pageData.mobileVideo.url : '',
  }
  const sources = [
    pageData.mobileHero.fluid,
    {
      ...pageData.bigScreen.fluid,
      media: `(min-width: 2000px)`,
    },
    {
      ...pageData.desktopHero.fluid,
      media: `(min-width: 900px)`,
    },
  ]

  const mediaBannerSources = [
    pageData.mediaBanner && pageData.mediaBanner.mobileImage && pageData.mediaBanner.mobileImage.fluid,
    pageData.mediaBanner && pageData.mediaBanner.desktopImage &&
    {
      ...pageData.mediaBanner.desktopImage.fluid,
      media: `(min-width: 900px)`,
    },
  ]

  const headerData = props.data.datoCmsHeader;


  const [productList, setProductList] = useState([]);

  return (
    <Layout isHome={true} seoData={pageData.seoMetaTags} country={props.pageContext.country} location={props.location}>
      <div className="cd-bf-mode">
        <ProductList createProductList={setProductList} />

        <Hero
          cname={"cd-hero-flowgun-go"}
          sources={sources}
          mediaData={mediaData}
          link={pageData.heroLink}
          buttonText={pageData.heroLinkText}
        >
          { // Hero Content is visible only if data exist
            pageData && pageData.heroContent && <div
              dangerouslySetInnerHTML={{ __html: pageData.heroContent }}>
            </div>
          }
          {
            pageData && pageData.heroLink && <Link to={`${pageData.heroLink.includes('tb-open') ? pageData.heroLink : pageData.heroLink + '/'}`} className="cd-absolute-link"><span>Shop</span></Link>
          }
        </Hero>


        <div className="cd-countdown-banner">
          <Countdown country={props.pageContext.country} />
        </div>

        {
          pageData && pageData.backgroundCards && <BackgroundCards data={pageData.backgroundCards} country={props.pageContext.country} />
        }

        <section className="cd-home-product-swiper">
          <div className="cd-max-width">
            <div className="cd-heading">
              {pageData.productHeadline && <h2>{pageData.productHeadline}</h2>}
            </div>
            <ProductSwiper labelBackground={headerData.saleLabelBackground.hex} color={headerData.saleLabelColor.hex} productList={productList} data={pageData.products} placement="Home - Best Sellers" />
          </div>
        </section>


        { // visible only if data exist
          pageData && pageData.rowGrid.length ? <ProductContent content={pageData.rowGrid} country={props.pageContext.country} /> : ""
        }

        { // MediaBanner is visible only if data exist
          pageData && pageData.mediaBanner && <MediaBanner
            sources={mediaBannerSources}
            desktopImage={pageData.mediaBanner.desktopImage}
            mobileImage={pageData.mediaBanner.mobileImage}
            videoSrcURL={pageData.mediaBanner.video}
            videoSrcURLMobile={pageData.mediaBanner.mobileVideo}
            audibleVideo={pageData.mediaBanner.audiobleVideo}
            audibleVideoMobile={pageData.mediaBanner.audibleVideoMobile}
          >
            <div dangerouslySetInnerHTML={{ __html: pageData.mediaBanner.bannerText }}></div>
          </MediaBanner>
        }
      </div>

    </Layout>
  )
}

export default HomePage;